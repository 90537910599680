export const loremCard = {
  questions: [
    {
      _id: 'sampleQuestion6',
      edition: 1,
      category: 'SL',
      question: 'Vestibulum ut massa eget lectus auctor tincidunt',
      answer: 'Etiam efficitur nibh ac consequat tempo',
      date: '2020-01-16T21:15:22.019Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion5',
      edition: 1,
      category: 'SN',
      question: 'Morbi pulvinar tellus quis fringilla iaculis',
      answer: 'Nam sed purus id nisi ultrices bibendum',
      date: '2020-01-22T14:56:14.112Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion4',
      edition: 1,
      category: 'AL',
      question: 'Vestibulum eu enim vehicula, congue justo id, maximus est',
      answer: 'Quisque eu mi volutpat',
      date: '2020-01-20T22:59:50.129Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion3',
      edition: 1,
      category: 'H',
      question: 'Quisque scelerisque nulla ut dapibus pulvinar',
      answer: 'malesuada',
      date: '2020-01-17T16:18:45.739Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion2',
      edition: 1,
      category: 'E',
      question: 'Mauris fringilla tellus in aliquam venenatis',
      answer: 'venenatis lacinia',
      date: '2020-01-20T22:59:57.215Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion1',
      edition: 1,
      category: 'G',
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      answer: 'luctus vitae lorem',
      date: '2020-01-22T14:49:26.235Z',
      __v: 0
    }
  ],
  flipped: false
};

export const emptyCard = {
  questions: [
    {
      _id: 'sampleQuestion1',
      edition: 1,
      category: 'SL',
      question:
        '\\*\\*\\*\\*\\* \\*\\*\\*\\*\\* \\*\\*\\*\\*\\* \\*\\*\\* \\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*',
      answer: '\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\* \\*\\*\\*\\*\\*',
      date: '2020-01-22T14:49:26.235Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion2',
      edition: 1,
      category: 'SN',
      question:
        '\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\* \\*\\* \\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\*',
      answer: '\\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*',
      date: '2020-01-20T22:59:57.215Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion3',
      edition: 1,
      category: 'AL',
      question:
        '\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\* \\*\\* \\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*',
      answer: '\\*\\*\\*\\*\\*\\*\\*\\*\\*',
      date: '2020-01-17T16:18:45.739Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion4',
      edition: 1,
      category: 'H',
      question:
        '\\*\\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\* \\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\* \\*\\*\\* \\*\\*\\*\\*\\*\\*\\* \\*\\*\\*',
      answer: '\\*\\*\\*\\*\\*\\*\\* \\*\\* \\*\\* \\*\\*\\*\\*\\*\\*\\*\\*',
      date: '2020-01-20T22:59:50.129Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion5',
      edition: 1,
      category: 'E',
      question:
        '\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\* \\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*',
      answer:
        '\\*\\*\\* \\*\\*\\* \\*\\*\\*\\*\\* \\*\\* \\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*',
      date: '2020-01-22T14:56:14.112Z',
      __v: 0
    },
    {
      _id: 'sampleQuestion6',
      edition: 1,
      category: 'G',
      question:
        '\\*\\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\* \\*\\*\\*\\*\\* \\*\\*\\*\\* \\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\*',
      answer:
        '\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\* \\*\\* \\*\\*\\*\\*\\*\\*\\*\\*\\* \\*\\*\\*\\*\\*',
      date: '2020-01-16T21:15:22.019Z',
      __v: 0
    }
  ],
  flipped: false
};
