import React, { createContext, useReducer, useEffect, useContext } from 'react';
import axios from 'axios';
import cardReducer from './cardReducer';
import { CurrentActions } from '../current/CurrentProvider';
import fullCategories from '../../data/categories';

const categories = fullCategories.map(c => c.id);

export const CardState = createContext();
export const CardActions = createContext();

const initialState = [];

const { REACT_APP_SERVER, REACT_APP_EDITION: edition } = process.env;

export default props => {
  const { setError, unsetError } = useContext(CurrentActions);
  const [cards, dispatch] = useReducer(cardReducer, initialState);

  const retrieveCard = async () => {
    unsetError();
    const used = cards.reduce(
      (acc, c) => [
        ...acc,
        ...c.questions.map(q => ({ _id: q._id, category: q.category }))
      ],
      []
    );
    try {
      const res = await axios.post(
        `${REACT_APP_SERVER}/cards`,
        { edition, categories, used },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      if (res && res.data) {
        const card = { questions: res.data, flipped: false };
        await dispatch({ type: 'ADD_CARD', payload: card });
        window.gtag('event', 'get_card');
        return true;
      }
    } catch (err) {
      const error =
        (((err || {}).response || {}).data || {}).error || 'unknown';
      window.gtag('event', 'error', {
        event_label: error
      });
      console.error(error);
      if (error === 'Ran out of questions') {
        setError('De momento no hay más tarjetas');
      } else setError('Error cargando siguiente tarjeta');
      return false;
    }
  };

  const flipCard = payload => dispatch({ type: 'FLIP_CARD', payload });

  useEffect(() => {
    retrieveCard();
    // eslint-disable-next-line
  }, []);
  return (
    <CardState.Provider value={cards}>
      <CardActions.Provider value={{ retrieveCard, flipCard }}>
        {props.children}
      </CardActions.Provider>
    </CardState.Provider>
  );
};
