import React, { useContext, useState, useEffect } from 'react';
import {
  FaPlusCircle,
  FaMinusCircle,
  FaUndoAlt,
  FaSpinner
} from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import Swipe from 'react-easy-swipe';
import { CardState, CardActions } from '../context/card/CardProvider';
import {
  CurrentState,
  CurrentActions
} from '../context/current/CurrentProvider';
import CardFront from './Game/CardFront';
import CardBack from './Game/CardBack';
import { loremCard, emptyCard } from '../data/sampleCards';
import './Game.scss';

export default () => {
  const cards = useContext(CardState);
  const [flipTransition, setFlipTransition] = useState(null);
  const [topCard, setTopCard] = useState(-1);
  const [changingCards, setChangingCards] = useState(false);
  const { index, error } = useContext(CurrentState);
  const { flipCard, retrieveCard } = useContext(CardActions);
  const { moveForward, moveBack, unsetError } = useContext(CurrentActions);
  const [height, setHeight] = useState(0);
  const activeTransitions =
    changingCards || !!flipTransition || !cards.length || index < 0;
  const prevNotReady = index === 0 || activeTransitions;
  const nextNotReady = index === cards.length - 1 || activeTransitions;

  useEffect(() => {
    if (cards.length === 1 && index < 0) next({ force: true });
    // eslint-disable-next-line
  }, [cards, index]);

  const flip = () => {
    if (!activeTransitions) {
      if (cards[index].flipped) setFlipTransition('toQuestions');
      else setFlipTransition('toAnswers');
      setTimeout(() => {
        flipCard(index);
        setTimeout(() => {
          setFlipTransition(null);
        }, 200);
      }, 200);
    }
  };

  const next = ({ force } = {}) => {
    if (!nextNotReady || force) {
      setChangingCards(true);
      setTopCard(topCard + 1);
      if (index >= 0 && cards[index].flipped) flip();
      if (index === cards.length - 2) retrieveCard();
      setTimeout(() => {
        setChangingCards(false);
        moveForward();
      }, 400);
    }
  };

  const prev = () => {
    if (!prevNotReady) {
      setChangingCards(true);
      moveBack();
      setImmediate(() => setTopCard(index - 1));
      setTimeout(() => {
        setChangingCards(false);
        if (cards[index].flipped) flip();
      }, 400);
    }
  };

  const topCardIn = topCard === index;
  const bgCardIn = topCard === index + 1;

  const handleKeydown = ({ keyCode }) => {
    switch (+keyCode) {
      case 38:
        flip();
        break;
      case 40:
        flip();
        break;
      case 37:
        prev();
        break;
      case 39:
        next();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);
    return () => document.removeEventListener('keydown', handleKeydown, false);
    // eslint-disable-next-line
  }, [changingCards, flipTransition, cards, index]);

  const setInnerHeight = ({ target }) => {
    setHeight(target.innerHeight);
  };

  useState(() => {
    window.addEventListener('resize', setInnerHeight);
    setInnerHeight({ target: window });
    return () => window.removeEventListener('resize', setInnerHeight);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Game" style={{ height: height + 'px' }}>
      <div className="bgCard bgCard-exit-active fullCard">
        <CardFront
          questions={
            (index != null && cards.length ? loremCard : emptyCard).questions
          }
        />
      </div>
      {index != null && cards.length > index + 1 && (
        <CSSTransition
          in={bgCardIn}
          timeout={400}
          classNames="bgCard"
          key={index + 1}
        >
          <div className="bgCard fullCard">
            {cards[index + 1].flipped ? (
              <CardBack questions={cards[index + 1].questions} />
            ) : (
              <CardFront
                questions={
                  (changingCards ? cards[index + 1] : loremCard).questions
                }
              />
            )}
          </div>
        </CSSTransition>
      )}
      {index >= 0 && cards.length && (
        <CSSTransition
          in={topCardIn}
          timeout={400}
          classNames="topCard"
          key={index}
          unmountOnExit={true}
        >
          <div className="topCard fullCard" alt="Tarjeta">
            {cards[index].flipped ? (
              <CardBack
                questions={cards[index].questions}
                fromFlipped={flipTransition === 'toAnswers'}
                toFlipped={flipTransition === 'toQuestions'}
                alt="Respuestas"
                active={true}
              />
            ) : (
              <CardFront
                questions={cards[index].questions}
                fromFlipped={flipTransition === 'toQuestions'}
                toFlipped={flipTransition === 'toAnswers'}
                alt="Preguntas"
                active={true}
              />
            )}
          </div>
        </CSSTransition>
      )}
      {!cards.length && !error && (
        <div className="loading">
          <FaSpinner />
        </div>
      )}
      <div className="buttons-grid">
        <div className="col">
          <div className="button prev wide-only">
            <button
              onClick={prev}
              disabled={prevNotReady}
              alt="Tarjeta anterior"
            >
              <FaMinusCircle />
            </button>
          </div>
        </div>
        <div className="col">
          {error ? (
            <div className="top-text error">
              {error}
              <button onClick={retrieveCard}>Reintentar</button>
              <button onClick={unsetError}>Ok</button>
            </div>
          ) : (
            <div className="top-text madeby">
              Creado por{' '}
              <a
                href="http://prototyping.barcelona"
                target="_blank"
                rel="noopener noreferrer"
              >
                App Prototyping Barcelona
              </a>
            </div>
          )}
          <Swipe
            onSwipeUp={flip}
            onSwipeDown={flip}
            onSwipeLeft={next}
            onSwipeRight={prev}
            tolerance={60}
            className="card-content invisible-card"
          ></Swipe>
          <div className="bottom-buttons">
            <div className="button prev tall-only">
              <button
                onClick={prev}
                disabled={prevNotReady}
                alt="Tarjeta anterior"
              >
                <FaMinusCircle />
              </button>
            </div>
            <div className="button flip">
              <button
                onClick={flip}
                disabled={activeTransitions}
                alt="Girar tarjeta"
              >
                <FaUndoAlt />
              </button>
            </div>
            <div className="button next tall-only">
              <button
                onClick={next}
                disabled={nextNotReady}
                alt="Tarjeta siguiente"
              >
                <FaPlusCircle />
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="button next wide-only">
            <button
              onClick={next}
              disabled={nextNotReady}
              alt="Tarjeta siguiente"
            >
              <FaPlusCircle />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
