import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import MD from 'markdown-to-jsx';
import categories from '../../data/categories';
import editions from '../../data/editions';
import './CardBack.scss';

const { REACT_APP_EDITION: edition } = process.env;

export default ({ questions, toFlipped, fromFlipped, active }) => {
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => setFirstRender(false), []);
  const flipped = toFlipped || (fromFlipped && firstRender);
  return (
    <CSSTransition in={!flipped} timeout={200} classNames="card">
      <div className="CardBack card-content">
        <div className="title">
          <h1>Preguntas Trivia</h1>
          <h2>{editions.find(e => e.id === +edition).name}</h2>
        </div>
        <div className="questions" alt={active ? `Respuestas` : null}>
          {questions.map(({ category, answer, _id }) => {
            const mainAnswer = answer.split('—')[0].trim();
            const explanation = (answer.split('—')[1] || '').trim();
            const categoryObj = categories.find(c => c.id === category);
            return (
              <div key={_id} className="question">
                <div
                  className="question-category"
                  style={{
                    backgroundColor: categoryObj.color
                  }}
                  alt={active ? `Categoría: ${categoryObj.name}` : null}
                ></div>
                <div
                  className="question-text"
                  alt={active ? `Respuesta` : null}
                >
                  <span className="main">
                    <MD>{mainAnswer}</MD>
                  </span>
                  <MD>{explanation.length ? ` — ${explanation}` : ''}</MD>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
};
