import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import MD from 'markdown-to-jsx';
import categories from '../../data/categories';
import './CardFront.scss';

export default ({ questions, toFlipped, fromFlipped, active }) => {
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => setFirstRender(false), []);
  const flipped = toFlipped || (fromFlipped && firstRender);
  return (
    <CSSTransition in={!flipped} timeout={200} classNames="card">
      <div
        className={`CardFront card-content${
          firstRender || toFlipped ? ' turned' : ''
        }`}
      >
        <div className="questions" alt={active ? 'Preguntas' : null}>
          {questions.map(({ category, question, _id }) => {
            const categoryObj = categories.find(c => c.id === category);
            return (
              <div key={_id} className="question">
                <div
                  className="question-category"
                  alt={active ? `Categoría: ${categoryObj.name}` : null}
                  style={{
                    backgroundColor: categoryObj.color
                  }}
                />
                <div className="question-text" alt={active ? 'Pregunta' : null}>
                  <MD>{question}</MD>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
};
