export default (state, { type, payload }) => {
  switch (type) {
    case 'ADD_CARD':
      return [...state, payload];
    case 'FLIP_CARD':
      return state.map((card, i) =>
        i === payload ? { ...card, flipped: !card.flipped } : card
      );

    default:
      return state;
  }
};
