import React, { createContext, useReducer } from 'react';
import currentReducer from './currentReducer';

export const CurrentState = createContext();
export const CurrentActions = createContext();

const initialState = { index: -1, error: null };

export default props => {
  const [state, dispatch] = useReducer(currentReducer, initialState);
  const moveForward = () => dispatch({ type: 'MOVE_FORWARD' });
  const moveBack = () => dispatch({ type: 'MOVE_BACK' });
  const setError = payload => dispatch({ type: 'SET_ERROR', payload });
  const unsetError = () => dispatch({ type: 'UNSET_ERROR' });
  return (
    <CurrentState.Provider value={state}>
      <CurrentActions.Provider
        value={{ moveForward, moveBack, setError, unsetError }}
      >
        {props.children}
      </CurrentActions.Provider>
    </CurrentState.Provider>
  );
};
