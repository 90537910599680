export default (state, { type, payload }) => {
  switch (type) {
    case 'MOVE_FORWARD':
      return { ...state, index: state.index + 1 };
    case 'MOVE_BACK':
      return { ...state, index: state.index - 1 };
    case 'SET_ERROR':
      return { ...state, error: payload };
    case 'UNSET_ERROR':
      return { ...state, error: null };

    default:
      return state;
  }
};
