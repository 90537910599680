import React from 'react';
import CurrentProvider from './context/current/CurrentProvider';
import CardProvider from './context/card/CardProvider';
import Game from './components/Game';
import './App.scss';

export default () => (
  <div className="App">
    <CurrentProvider>
      <CardProvider>
        <Game />
      </CardProvider>
    </CurrentProvider>
  </div>
);
